// websocketStore.js
import { ref } from 'vue';
import { useConversationStore } from '/entrypoints/stores/conversationStore.js';
import { smoothScrollToBottom } from '/entrypoints/utils/scrollUtils.js';

export const useWsMessageStore = () => {
  const socket = ref(null);
  const conversationStore = useConversationStore();
  const connect = (conversationId) => {
    socket.value = new WebSocket('ws://localhost:3000/cable');

    socket.value.onopen = () => {
      console.log("Successfully connected to the WebSocket server...");
      const msg = {
        command: 'subscribe',
        identifier: JSON.stringify({
          channel: 'MessagesChannel',
          conversation_id: conversationId
        })
      };
      socket.value.send(JSON.stringify(msg));
    };

    socket.value.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "ping") return;

      if (data.message && data.message.owner.type !== "AccountUser") {
        conversationStore.conversation.messages.push(data.message);
        smoothScrollToBottom('chat-area');
        console.log("Received data from server", data);
      }
    };

    socket.value.onclose = () => {
      console.log('WebSocket connection closed');
    };
  };

  const disconnect = () => {
    if (socket.value) {
      socket.value.close();
    }
  };

  return {
    connect,
    disconnect,
    socket
  };
};
